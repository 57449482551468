import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { BlogPost, GetTagsQueryQuery } from '../../graphql-types'
import { Posts } from '../components/Posts'

export default ({ pageContext: { posts } }: PageProps<void, { posts: BlogPost[] }>) => {
  return (
    <Posts posts={posts} />
  )
}

export const pageQuery = graphql`
  query GetTagsQuery($tag: String!) {
  posts: allBlogPost(filter: {tags: {in: [$tag]}}) {
    totalCount
    nodes {
      id
      title
    }
  }
}
`
